const state = () => ({
  collateral_info: {
    loan_id: "",
    stage: "",
    // loan_type: "SMEL",
    collateral_information: "Land and Building",
    other_collateral: "",
    collateral_loan: "",
    collateral_location: "",
    collateral_value: "",
  },
});

const getters = {
  getCollateralData(state) {
    return state.collateral_info;
  },
};
const actions = {};
const mutations = {
  updateLoanID: (state, loan_id) =>
    (state.collateral_info.loan_id = loan_id),

  collateralDetails: (state, responseData) => {
    state.collateral_info.stage = responseData.loan.stage
    state.collateral_info.collateral_information = responseData?.collateral_information;
    state.collateral_info.collateral_location = responseData.collateral_location;
    state.collateral_info.collateral_loan = responseData.collateral_loan

    state.collateral_info.collateral_value = responseData.collateral_value;
    if (
      state.collateral_info.collateral_information == "Land and Building" ||
      state.collateral_info.collateral_information == null
    ) {
      state.collateral_info.collateral_information =
        responseData.collateral_information;
      state.collateral_info.other_collateral = '';
    } else {
      state.collateral_info.collateral_information =
        responseData.collateral_information;

      state.collateral_info.other_collateral = responseData.collateral_information;
      state.collateral_info.collateral_loan = ''
    }
    // }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
