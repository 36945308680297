const state = () => ({
  upload_document: {
    loan_id: "",
    stage: "",
    // remark: "",
    applicant: {
      remark: "",
      nrc_front: "",
      nrc_back: "",
      census_front: "",
      census_back: "",
      employee_card: "",
      selfie_photo: "",
      bill_photo: "",
      salary_slip: "",
      ward_recommendation_letter: "",

      company_type: "Company",
      company_license: "",
      articles_of_association: "",
      company_form: "",
      current_director_list: "",
      business_photo: "",
      business_bill: "",
      partnership_contract: "",
      member_list: "",
      member_sign: "",
      loan_amount: "",
      tenor: "",

      land_permit: [],
      agreement_contract: [],
      other_payment_challan: [],
      other_collateral: [],

      type: "applicant-document",
      completed_percent: 0,
    },
    guarantor_family: {
      nrc_front: "",
      nrc_back: "",
      census_front: "",
      census_back: "",
      employee_card: "",
      selfie_photo: "",
      bill_photo: "",
      type: "guarantor-family-document",
      completed_percent: 0,
    },
    guarantor_colleague: {
      nrc_front: "",
      nrc_back: "",
      census_front: "",
      census_back: "",
      employee_card: "",
      selfie_photo: "",
      bill_photo: "",
      type: "guarantor-colleague-document",
      completed_percent: 0,
    },
    // collateral: {
      // land_permit: "",
      // agreement_contract: "",
      // other_payment_challan: "",
      // type: "collateral-document",
      // completed_percent: 0,
    // },
  },
});

const getters = {};
const actions = {};
const mutations = {
  updateLoanID: (state, loan_id) => (state.upload_document.loan_id = loan_id),

  documentDetails: (state, responseData) => {

    state.upload_document.applicant.loan_amount = responseData.plan.unit_price;
    state.upload_document.applicant.tenor = responseData.plan.tenure;
    state.upload_document.applicant.remark = responseData.remark;  


    if (responseData.applicant != null) {
      state.upload_document.stage = responseData.stage;
      // if (responseData.applicant.nrc_front) {
        state.upload_document.applicant.nrc_front =
          responseData?.applicant.nrc_front;
        state.upload_document.applicant.nrc_back =
          responseData?.applicant.nrc_back;
        state.upload_document.applicant.census_front =
          responseData?.applicant.census_front;
        state.upload_document.applicant.census_back =
          responseData?.applicant.census_back;
        state.upload_document.applicant.employee_card =
          responseData.applicant.employee_card;
        state.upload_document.applicant.selfie_photo =
          responseData.applicant.selfie_photo;
        state.upload_document.applicant.bill_photo =
          responseData.applicant.bill_photo;
        state.upload_document.applicant.salary_slip =
          responseData.applicant.salary_slip;
        state.upload_document.applicant.ward_recommendation_letter =
          responseData.applicant.ward_recommendation_letter;
        state.upload_document.applicant.company_type =
          responseData?.applicant.company_type;

          

        // Company
        if(state.upload_document.applicant.company_type == 'Company') {
          state.upload_document.applicant.company_license = responseData.applicant.company_license;
          state.upload_document.applicant.articles_of_association = responseData.applicant.articles_of_association;
          state.upload_document.applicant.company_form =  responseData.applicant.company_form;
          state.upload_document.applicant.current_director_list = responseData.applicant.current_director_list;
          state.upload_document.applicant.meeting_decision = responseData.applicant.meeting_decision
          
          state.upload_document.applicant.business_photo = ''
          state.upload_document.applicant.business_bill = ''
          state.upload_document.applicant.partnership_contract = ''
          state.upload_document.applicant.member_list = ''
          state.upload_document.applicant.member_sign = ''
        } 
        // else {
        //   state.upload_document.applicant.company_license = '';
        //   state.upload_document.applicant.articles_of_association = ''
        //   state.upload_document.applicant.company_form = ''
        //   state.upload_document.applicant.current_director_list = ''
        //   state.upload_document.applicant.meeting_decision = ''
        // }
        // Company

        // state.upload_document.applicant.loan_amount = responseData?.plan.unit_price;
        // state.upload_document.applicant.tenor = responseData?.plan.tenure;
        // state.upload_document.applicant.remark = responseData?.remark;  

       
        
        // Private
        if(state.upload_document.applicant.company_type == 'Private') {
          state.upload_document.applicant.business_photo = responseData.applicant.business_photo;
          state.upload_document.applicant.business_bill = responseData.applicant.business_bill;

          state.upload_document.applicant.company_license = '';
          state.upload_document.applicant.articles_of_association = ''
          state.upload_document.applicant.company_form = ''
          state.upload_document.applicant.current_director_list = ''
          state.upload_document.applicant.meeting_decision = ''

          state.upload_document.applicant.partnership_contract = ''
          state.upload_document.applicant.member_list = ''
          state.upload_document.applicant.member_sign = ''
        } 
        // else {
        //   state.upload_document.applicant.business_photo = ''
        //   state.upload_document.applicant.business_bill = ''
        // }
        // Private

        // Partnertship
        if(state.upload_document.applicant.company_type == 'Partnership') {
          state.upload_document.applicant.partnership_contract = responseData.applicant.partnership_contract;
          state.upload_document.applicant.member_list = responseData.applicant.member_list;
          state.upload_document.applicant.member_sign = responseData.applicant.member_sign;

          state.upload_document.applicant.company_license = '';
          state.upload_document.applicant.articles_of_association = ''
          state.upload_document.applicant.company_form = ''
          state.upload_document.applicant.current_director_list = ''
          state.upload_document.applicant.meeting_decision = ''
          state.upload_document.applicant.business_photo = ''
          state.upload_document.applicant.business_bill = ''

        } 
        // else {
        //   state.upload_document.applicant.partnership_contract = ''
        //   state.upload_document.applicant.member_list = ''
        //   state.upload_document.applicant.member_sign = ''
        // }
        // Partnertship
      
        state.upload_document.applicant.land_permit =
          responseData.applicant.land_permit;
        state.upload_document.applicant.agreement_contract =
          responseData.applicant.agreement_contract;
        state.upload_document.applicant.other_payment_challan =
          responseData.applicant.other_payment_challan;
          state.upload_document.applicant.other_collateral =
          responseData.applicant.other_collateral;
          

      state.upload_document.applicant.completed_percent =
        responseData.applicant.completed_percent;
    }

    if (responseData.guarantor_family != null) {
        state.upload_document.guarantor_family.nrc_front =
          responseData.guarantor_family.nrc_front;
        state.upload_document.guarantor_family.nrc_back =
          responseData.guarantor_family.nrc_back;
        state.upload_document.guarantor_family.census_front =
          responseData.guarantor_family.census_front;
        state.upload_document.guarantor_family.census_back =
          responseData.guarantor_family.census_back;
        state.upload_document.guarantor_family.employee_card =
          responseData.guarantor_family.employee_card;
        state.upload_document.guarantor_family.selfie_photo =
          responseData.guarantor_family.selfie_photo;
        state.upload_document.guarantor_family.bill_photo =
          responseData.guarantor_family.bill_photo;

      state.upload_document.guarantor_family.completed_percent =
        responseData.guarantor_family.completed_percent;
    }

    if (responseData.guarantor_colleague != null) {
        state.upload_document.guarantor_colleague.nrc_front =
          responseData.guarantor_colleague.nrc_front;
        state.upload_document.guarantor_colleague.nrc_back =
          responseData.guarantor_colleague.nrc_back;
        state.upload_document.guarantor_colleague.census_front =
          responseData.guarantor_colleague.census_front;
        state.upload_document.guarantor_colleague.census_back =
          responseData.guarantor_colleague.census_back;
        state.upload_document.guarantor_colleague.employee_card =
          responseData.guarantor_colleague.employee_card;
        state.upload_document.guarantor_colleague.selfie_photo =
          responseData.guarantor_colleague.selfie_photo;
        state.upload_document.guarantor_colleague.bill_photo =
          responseData.guarantor_colleague.bill_photo;

      state.upload_document.guarantor_colleague.completed_percent =
        responseData.guarantor_colleague.completed_percent;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
