import axios from "axios";
const state = () => ({
    country_list: [],
    city_list: [],
    township_list: []
});
const getters = {
    getStateList(state) {
        return state.country_list
    },
    getCityList(state) {
        return state.city_list
    },
    getTownshipList(state) {
        return state.township_list
    }
};
const mutations = {
    getCountryState(state) {
        return new Promise((resolve, reject) => {
            const odooUrl = process.env.VUE_APP_Odoo_URL
            axios.get(`${odooUrl}v1/country-state`)
            .then((response) => {
                state.country_list = response.data
                resolve(state.country_list)
            }).catch(err => {
                reject(err)
            })
        })
    },
    async getCity(state) {
        // Loading.show();
        return new Promise((resolve, reject) => {
            const odooUrl = process.env.VUE_APP_Odoo_URL
            axios.get(`${odooUrl}v1/city`, {})
            .then((response) => {
                state.city_list = response.data.data
                resolve(state.city_list)
            })
            .catch((error) => {
                reject(error)
            });
        })
    },
    async getTownship(state) {
        // Loading.show();
        return new Promise((resolve, reject) => {
            const odooUrl = process.env.VUE_APP_Odoo_URL
            axios.get(`${odooUrl}v1/township`, {})
            .then((response) => {
                state.township_list = response.data.data
                resolve(state.township_list)
            })
            .catch((error) => {
                reject(error)
            });
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};







