export default {
  cl_document: "အပေါင်ပစ္စည်းချေးငွေ စာရွက်စာတမ်းများ",
  total_finish: "ပြီးမြောက်မှု",
  fullfill_data: "လိုအပ်သောအချက်အလက်များဖြည့်ပါ",
  applicant_information: "‌လျှောက်ထားသူ၏ အချက်အလက်များ",
  occupation_information: "အပေါင်ပစ္စည်းနှင့် ဆက်စပ်အချက်အလက်များ",
  guarantor1_information: "အာမခံလူပုဂ္ဂိုလ် ၁ အချက်အလက်များ",
  guarantor2_information: "အာမခံလူပုဂ္ဂိုလ် ၂ အချက်အလက်များ",
  upload_document: "ငွေချေးသူ၏ စာရွက်စာတမ်းများတင်ရန်",
  applicant_title: "လျောက်ထားသူအချက်အလက်",
  need_to_update: "ပြင်ဆင်ရန်လိုအပ်သည်",
  customer_name: "ငွေချေးသူအမည်",
  nrc: "နိုင်ငံသားစိစစ်ရေးကဒ်ပြားအမှတ်",
  father_name: "အဖအမည်",
  phone_number: "ဖုန်းနံပါတ်",
  other_phone_number: "အခြားဖုန်းနံပါတ်",
  email: "အီးမေးလ်",
  date_of_birth: "မွေးသက္ကရာဇ်",
  nationality: "နိုင်ငံသား",
  Myanmar: "မြန်မာ",
  Other: "အခြား",
  gender: "ကျား/မ",
  Male: "ကျား",
  Female: "မ",
  marital_status: "အိမ်ထောင်",
  Single: "မရှိ",
  Widowed: "မုဆိုးဖို/မ",
  Married: "ရှိ",
  Divorced: "ကွာရှင်း",
  bank_account: "ဘဏ်အကောင့်ရှိပါသလား",
  Yes: "ရှိ",
  No: "မရှိ",
  own_business: "ကိုယ်ပိုင်လုပ်ငန်းရှိပါသလား",
  company_or_business_info: "ကုမ္ပဏီ (သို့) လုပ်ငန်း ဆိုင်ရာအချက်အလက် ",
  business_name: "ကုမ္ပဏီ / လုပ်ငန်း အမည်",
  business_phone: "ကုမ္ပဏီ / လုပ်ငန်း ဖုန်းနံပါတ် ",
  business_industry: "လုပ်ငန်းနယ်ပယ်အမျိုးအစား",
  Production: "ထုတ်လုပ်ရေး",
  Import: "သွင်းကုန်",
  Distribution: "ဖြန့်ချိရေး",
  Service: "ဝန်ဆောင်မှု ပေးခြင်း",
  Export: "ထုတ်ကုန်",
  business_address_info: "ကုမ္ပဏီ/ လုပ်ငန်း လိပ်စာ",
  business_license: "လုပ်ငန်းလိုင်စင်ရှိပါသလား",
  pay_taxes: "ယခင်နှစ် နှင့် ရှေ့ သုံးနှစ်  အခွန် ပေးဆောင် ခဲ့ပါသလား",
  pay_yes: "ဆောင်ခဲ့သည်",
  pay_no: "မဆောင်ခဲ့ပါ",
  company_register: "ကုမ္ပဏီမှတ်ပုံတင်နံပါတ်",
  tax_payment: "နောက်ဆုံး အခွန်ဆောင်ခဲ့သည့်နေ့",
  investment_amount: "ရင်းနှီး ငွေပမာဏ",
  employee_type: "ဝန်ထမ်းအမျိုးအစား",
  "Full-time": "အချိန်ပြည့်ဝန်ထမ်း",
  "Part-time": "အချိန်ပိုင်းဝန်ထမ်း",
  permanent_address: "အမြဲတမ်းနေရပ်လိပ်စာအချက်အလက်များ",
  region: "တိုင်း /ဒေသ",
  city: "မြို့တော်",
  township: "မြို့နယ်",
  ward_or_village: "ရပ်ကွက် / ကျေးရွာ",
  Ward: "ရပ်ကွက်",
  Village: "ကျေးရွာ",
  street_address: "လမ်းလိပ်စာ",
  building_no: "တိုက်အမှတ်",
  floor: "အလွှာ",
  type_of_residence: "နေထိုင်မှုအမျိုးအစား",
  Owner: "ကိုယ်ပိုင်",
  Rental: "အငှား",
  Parental: "မိဘအိမ်",
  Hostel: "အဆောင်",
  living_with: "အတူတကွနေထိုင်ခြင်း",
  Parent: "မိဘ",
  Relative: "အမျိုး",
  Spouse: "အိမ်ထောင်ဖက်",
  Alone: "တစ်ယောက်တည်း",
  Friend: "သူငယ်ချင်း",
  year_of_stay: "နေထိုင်သည့်နှစ်",
  year: "နှစ်",
  month: "လ",
  emergency_contact: "ဆက်သွယ်ရန်မိသားစု အချက်အလက်",
  emergency_name: "နာမည်",
  address_of_contact: "ဆက်သွယ်ရမည့်မိသားစုနေရပ်လိပ်စာ",
  relationship_with_applicant: "တော်စပ်မှု",
  Offspring: "အမျိုးအနွယ်",
  Sibling: "မွေးချင်းမောင်နှမ",
  back: "နောက်သို့",
  next: "ရှေ့သို့",
  submit: "တင်ပြပါ",
  done: "ပြီးပါပြီ",
  apply: "အတည်ပြုမည်",
  save_apply_information: "အချက်အလက်များအားသိမ်းဆည်းခြင်း",
  confirmation: "အတည်ပြုချက်ကို စောင့်ပါ။",
  save_information: "အချက်အလက်များအားသိမ်းဆည်းခြင်း",
  successfully_saved: "သင်၏အချက်အလက်များကိုအောင်မြင်စွာ သိမ်းဆည်းပြီးပါပြီ",
  want_exit: "ထွက်ရန်သေချာပါသလား",
  yes: "ထွက်မယ်",
  no: "မထွက်ပါ",
  is_required: "လိုအပ်ပါသည်",
  correct_phone_number: "ဖုန်းနံပါတ်အမှန်ထည့်ပါ",
  no_results: "ရလဒ်မရှိပါ",
  greater_than: "၁၁ ထက်ပိုကြီးလို့မရပါ",
  correct_month: "ကျေးဇူးပြု၍ မှန်ကန်သော လကို ထည့်ပါ",
  correct_salary_date: "ကျေးဇူးပြု၍ မှန်ကန်သော Salary Date ကို ထည့်ပါ",
  correct_email: "ကျေးဇူးပြု၍ မှန်ကန်သော Email ကို ထည့်ပါ",
  correct_nrc: "ကျေးဇူးပြု၍ မှန်ကန်သော NRC Number ကို ထည့်ပါ",
  enter_nrc: "NRC နံပါတ်ထည့်ပါ",
  enter_validate_nrc: "ကျေးဇူးပြု၍ တရားဝင် NRC နံပါတ်ကို ထည့်ပါ",
  occupation_title: "အလုပ်အကိုင်အချက်အလက်",
  occupation_company_information: "ကုမ္ပဏီ အချက်အလက်များ",
  occupation_company_address: "ကုမ္ပဏီ လိပ်စာ",
  company_phone: "ကုမ္ပဏီ ဖုန်းနံပါတ်",
  hr_name: "HR အမည်",
  hr_phone: "HR ဖုန်းနံပါတ်",
  company_status: "ကုမ္ပဏီ အခြေအနေ",
  "Public Company": "အများပိုင် ကုမ္ပဏီ",
  "Private Company": "ပုဂ္ဂလိက ကုမ္ပဏီ",
  Factory: "စက်ရုံ",
  SME: "SME",
  NGO: "NGO",
  "Government Office": "အစိုးရရုံး",
  Military: "စစ်ရေးရုံး",
  Police: "ရဲ",
  occupation_personal_information: "မိမိအလုပ်အကိုင်အချက်အလက်",
  position: "ရာထူး",
  department: "ဌာန",
  department_name: "ဌာနအမည်",
  year_of_service: "လုပ်သက်",
  monthly_basic_income: "လစဉ်ဝင်ငွေ",
  additional_income: "အခြား၀င်ငွေ",
  total_income: "စုစုပေါင်း၀င်ငွေ",
  monthly_total_income: "လစဉ်ဝင်ငွေ",
  collateral_want: "လိုချင်သောပစ္စည်းများ",
  required_field: "ကွက်လပ်မှာဖြည့်ရန်လိုအပ်ပါသည်",
  select_collateral: "အပေါင်ပစ္စည်းချေးငွေထဲမှ တစ်ခုကို ရွေးပါ",
  "Land and Building": "မြေနှင့် အဆောက်အအုံ",
  "Other Collatetal": "အခြားအပေါင်ပစ္စည်းများ",
  "Land Permit from Government":
    "မြေပါမစ်ခွင့်ပြုမိန့် (သို့) မြေငှားစာချုပ်(ဂရမ်)",
  "Form 105 and 7 for Farm and Garden": "မြေပုံစံ (၇ ၊ ၁၀၅ (သို့) ၁၀၆) စာချုပ်",
  "BCC Documents for Contract Apartment":
    "အဆောက်အဦး ပြီးစီးကြောင်းသက်သေခံလက်မှတ် (BCC)",
  "Owner Book for Government Apartment":
    "တိုက်ခန်း ပိုင်ဆိုင်ခွင့်ပြုမိန့် ( Owner Book )",
  collateral_location: "အပေါင်ပစ္စည်းတည်နေရာ",
  collateral_value: "အပေါင်ပစ္စည်းတန်ဖိုး",
  salary_date: "လစာထုတ်သည့်ရက်",
  guarantor1_title: "အာမခံသူ ၁ ၏အချက်အလက်",
  guarantor_personal_information: "အာမခံသူ၏အချက်အလက်",
  relationship_with_customer: "တော်စပ်ပုံ",
  guarantor_name: "အာမခံအမည်",
  guarantor_permanent_address: "အာမခံသူ၏အမြဲတမ်းနေရပ်လိပ်စာ",
  guarantor_current_address: "အာမခံသူ၏လက်ရှိနေရပ်လိပ်စာ",
  guarantor_company_information: "ကုမ္ပဏီ အချက်အလက်များ",
  guarantor_company_address: "ကုမ္ပဏီ လိပ်စာ",
  occupation: "အလုပ်အကိုင်",
  "Own Business": "ကိုယ်ပိုင်လုပ်ငန်း",
  "Company Staff": "ကုမ္ပဏီဝန်ထမ်း",
  "Government Staff": "အစိုးရဝန်ထမ်း",
  guarantor2_title: "အာမခံသူ ၂ ၏အချက်အလက်",
  company_name: "ကုမ္ပဏီ အမည်",
  nrc_front: "မှတ်ပုံတင်ရှေ့",
  nrc_back: "မှတ်ပုံတင်နောက်",
  census_front: "သန်းခေါင်စာရင်းရှေ့",
  census_back: "သန်းခေါင်စာရင်းနောက်",
  employee_card: "၀န်ထမ်းကဒ်",
  selfie_photo: "ဓါတ်ပုံ",
  electricity_bill: "မီတာဘေလ်",
  ward_recommendation_letter: "ရပ်ကွက်ထောက်ခံစာ",
  salary_slip: "လစဉ်၀င်ငွေ",
  company_recommendation_letter: "၀န်ထမ်းဖြစ်ကြောင်းထောက်ခံစာ",
  proof_address: "နေရပ်လိပ်စာအထောက်အထား",
  land_and_building: "အပေါင်ပစ္စည်း- မြေယာနှင့် အဆောက်အအုံ-",
  attached_file:
    "ပူးတွဲပါဖိုင်ကို ကျေးဇူးပြု၍ အပ်လုဒ်တင်ပြီး ပူးတွဲပါဖိုင်တစ်ခု သို့မဟုတ် တစ်ခုထက်ပို၍ အပ်လုဒ်လုပ်နိုင်ပါသည်။",
  permit_government: "မြေယာခွင့်ပြုမိန့်",
  agreement_contract_for: "မြေ/ အဆောက်အဦအတွက် သဘောတူညီချက် စာချုပ်",
  agreement_contract: "သဘောတူညီချက် စာချုပ်",
  payment_challen: "အစိုးရထံမှ အခြားသော ငွေပေးချေမှုများ",
  other_challen: "အခြားငွေပေးချေမှု",
  collateral_other: "အပေါင်ပစ္စည်း- အခြား-",
  upload: "တင်ရန်",
  other_collateral: "အခြားအပေါင်ပစ္စည်း",
  company_information: "ကုမ္ပဏီသတင်းအချက်အလက်",
  company_license: "ကုမ္ပဏီလိုင်စင်",
  association_and_record: "အသင်းအဖွဲ့များနှင့် မှတ်တမ်းများ",
  company_form: "ကုမ္ပဏီပုံစံ (၆)",
  current_condition: "လက်ရှိအခြေအနေစာရင်း",
  meeting_decision: "အစည်းအဝေးဆုံးဖြတ်ချက်",
  business_photo: "စီးပွားရေးဓာတ်ပုံ",
  business_tax: "လုပ်ငန်းသုံးလျှပ်စစ်ငွေတောင်းခံလွှာ/ လုပ်ငန်းခွန်",
  partnership_contract: "အစုစပ်စာချုပ်",
  member_lists: "အဖွဲ့ဝင်စာရင်းများ",
  all_member_signs:
    "အကောင့်ဖွင့်ရန် အဖွဲ့ဝင်အားလုံး လက်မှတ်များနှင့် ချေးငွေတောင်းခံရန်ဆုံးဖြတ်ချက်",
  loan_amount: "ငွေပမာဏ",
  tenor: "ကြာချိန်",
  reason: "အကြောင်းပြချက်",
  accept_teams_and_conditions: `Win Finance ၏ စည်းမျဉ်းစည်းကမ်းများကို လက်ခံပါသည်။`,
  experience_salary_position_must_be_included:
    "လုပ်သက်၊ လစာ၊ ရာထူးပါဝင်ရပါမည်။",
  recent_bill_as_proof_address:
    "လိပ်စာအထောက်အထားအတွက် လက်တလောအခွန်ဆောင်ထားသော ပြေစာ",
  you_can_upload_one_or_more_acceptable_attachment_for_your_loan_document:
    "သင့်ချေးငွေ စာရွက်စာတမ်းများကို ပူးတွဲပါဖိုင်တစ်ခု (သိုမဟုတ်) တစ်ခုထက်ပို၍ အပ်လုဒ်လုပ်နိုင်ပါသည်။",
  electricity_bill_or_recent_bill_or_country_council_bill:
    "လက်ရှိနေထိုင်မှုအိမ်ရဲ့ မီတာဘေလ်စာရွက် (ဓါတ်ပုံ)",
  Company: "ကုမ္ပဏီ",
  Private: "ကိုယ်ပိုင်စီးပွားရေး",
  Partnership: "အစုစပ်လုပ်ငန်း"
};
