import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import applicant from "./modules/applicant";
import collateral from "./modules/collateral";
import family from "./modules/family";
import colleague from "./modules/colleague";
import upload from "./modules/upload";
import fetchOdooApi from './modules/fetch_odoo_api'

// initial state
let initialState = {
  applicant: applicant.state,
  collateral: collateral.state,
  family: family.state,
  colleague: colleague.state,
  upload: upload.state,
  fetchOdooApi: fetchOdooApi.state
};

const store = createStore({
  modules: {
    applicant,
    collateral,
    family,
    colleague,
    upload,
    fetchOdooApi
  },
  state: {},
  getters: {},
  actions: {},
  mutations: {
    reset(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
  },
  plugins: [createPersistedState()],
});


export default store;
