const state = () => ({
  applicant_information: {
    loan_id: "",
    stage: "",
    name: "",
    nrc_state_id: "",
    nrc_state_number: "",
    nrc_state_burmese_number: "",
    nrc_district_id: "",
    nrc_district_name: "",
    nrc_district_burmese_name: "",
    nrc_type_id: "",
    nrc_type_name: "",
    nrc_type_burmese_name: "",
    nrc_number: "",
    father_name: "",
    phone_number: "",
    other_phone_number: "",
    email: "",
    date_of_birth: "",
    nationality: "",
    other_nationality: "",
    gender: "",
    marital_status: "",
    has_bank_account: 1,
    country_state_id: "",
    city_id: "",
    township_id: "",
    place_type: "Ward",
    ward_or_village_name: "",
    street: "",
    building_number: "",
    floor: "",
    residence_type: "",
    living_with: "",
    years_of_stay: "",
    months_of_stay: "",
    have_own_business: 1,
    type: "customer",

    company_name: "",
    company_phone: "",
    business_type: "Production",
    other_industry: "",

    // company_type: "Public Company",
    other_business_type: "",

    have_business_license: "",
    have_pay_tax_for_last_three_years: 1,
    company_register_number: "",
    tax_payment_last_date: "",
    investment_amount: "",
    currency_code: "MMK",
    years_of_service: "",
    months_of_service: "",
    total_income: 0,

    employee_type: '',
    other_employee_type: '',
    position: '',
    department_name: '',
    hr_name: '',
    hr_phone: '',
    salary_payday: ''
  },
  company_address: {
    country_state_id: "",
    city_id: "",
    township_id: "",
    place_type: "Ward",
    ward_or_village_name: "",
    street: "",
    building_number: "",
    floor: "",
  },
});

const getters = {
  getApplicantData(state) {
    return state.applicant_information;
  },

  // getBusinessData(state) {
  //   return state.business_information;
  // },

  getCompanyData(state) {
    return state.company_address;
  },
};
const actions = {};
const mutations = {
  updateLoanID: (state, loan_id) =>
    (state.applicant_information.loan_id = loan_id),

  applicantDetails: (state, responseData) => {
    console.log('Resss', responseData);
    // if (responseData.loan.customer.information.nrc) {
      state.applicant_information.stage = responseData.stage;
      state.applicant_information.nrc_state_id =
        responseData.nrc?.state_id;
      state.applicant_information.nrc_state_number =
        responseData.nrc?.state_number;
      state.applicant_information.nrc_state_burmese_number =
        responseData.nrc?.state_burmese_number;
      state.applicant_information.nrc_district_id =
        responseData.nrc?.district_id;
      state.applicant_information.nrc_district_name =
        responseData.nrc?.district_name;
      state.applicant_information.nrc_district_burmese_name =
        responseData.nrc?.district_burmese_name;
      state.applicant_information.nrc_type_id =
        responseData.nrc?.type_id;
      state.applicant_information.nrc_type_name =
        responseData.nrc?.type_name;
      state.applicant_information.nrc_type_burmese_name = responseData.nrc?.type_burmese_name;
    // }

    state.applicant_information.nrc_number = responseData.nrc_number;

    state.applicant_information.father_name = responseData.father_name;
    state.applicant_information.date_of_birth = responseData.date_of_birth;

    state.applicant_information.name = responseData.name;
    state.applicant_information.phone_number = responseData.phone_number;
      // }

      // if (responseData.loan.customer.information.other_phone_number != null) {
        state.applicant_information.other_phone_number = responseData.other_phone_number;
      // }

      // if (responseData.loan.customer.information.email != null) {
        state.applicant_information.email = responseData.email;
      // }
    // }

    if (
      state.applicant_information.nationality == "Myanmar" ||
      state.applicant_information.nationality == null
    ) {
      state.applicant_information.nationality = responseData.nationality;
      state.applicant_information.other_nationality = null;
    } else {
      state.applicant_information.nationality = responseData.nationality;
      state.applicant_information.other_nationality = responseData.nationality;
    }

    state.applicant_information.gender = responseData.gender;

    state.applicant_information.marital_status = responseData.marital_status;

    state.applicant_information.has_bank_account = responseData.has_bank_account;

    state.applicant_information.country_state_id = responseData.permanent_address.country_state_id;
    state.applicant_information.city_id = responseData.permanent_address.city_id;
    state.applicant_information.township_id = responseData.permanent_address.township_id;

    state.applicant_information.place_type = responseData.permanent_address.place_type;

    state.applicant_information.ward_or_village_name =
      responseData.permanent_address?.ward_or_village_name;

    state.applicant_information.residence_type =
      responseData.permanent_address.residence_type;

    state.applicant_information.living_with =
      responseData.permanent_address.living_with;

    state.applicant_information.years_of_stay =
      responseData.permanent_address.years_of_stay;
    state.applicant_information.months_of_stay =
      responseData.permanent_address.months_of_stay;
    state.applicant_information.have_own_business = responseData.have_own_business;
    state.applicant_information.street = responseData.permanent_address.street;
    state.applicant_information.building_number =
      responseData.permanent_address.building_number;
    state.applicant_information.floor = responseData.permanent_address.floor;
    state.applicant_information.type = responseData.permanent_address.type;

    // if (responseData.company_name) {
      state.applicant_information.company_name = responseData?.company_name;
    // }

    // if (state.applicant_information.company_phone) {
      state.applicant_information.company_phone = responseData?.company_phone;
    // }

    // state.applicant_information.business_type = responseData?.business_type;

    // if (responseData.business_type) {
      if(state.applicant_information.have_own_business == 1) {
        if (
          state.applicant_information.business_type == "Production" ||
          state.applicant_information.business_type == "Import" ||
          state.applicant_information.business_type == "Distribution" ||
          state.applicant_information.business_type == "Service" ||
          state.applicant_information.business_type == "Export" ||
          state.applicant_information.business_type == null || state.applicant_information.business_type == ''
        ) {
          state.applicant_information.business_type =
            responseData?.business_type;
          state.applicant_information.other_industry = null;
        } else {
          state.applicant_information.business_type =
            responseData?.business_type;
          state.applicant_information.other_industry = responseData?.business_type;
        }
      } else {

        if(responseData.business_type == 'Public Company' || responseData.business_type == 'Private Company' || responseData.business_type == 'Factory' || 
        responseData.business_type == 'SME' || responseData.business_type == 'Specialist' || responseData.business_type == 'NGO' || responseData.business_type == 'Government Office' ||
        responseData.business_type == 'Military' || responseData.business_type == 'Police'  || responseData.business_type == null) {
          state.applicant_information.business_type = responseData.business_type
          state.applicant_information.other_business_type = '';
        } else {
          state.applicant_information.other_business_type = responseData.business_type;
          state.applicant_information.business_type = responseData.business_type;
        }
      }

    if(responseData.employee_type == 'Full-time' || responseData.employee_type == 'Part-time' || responseData.employee_type == null) {
      state.applicant_information.employee_type = responseData.employee_type
      state.applicant_information.other_employee_type = ''
    } else {
      state.applicant_information.other_employee_type= responseData.employee_type;
      state.applicant_information.employee_type = responseData.employee_type;
    }

    state.applicant_information.position = responseData.position;
    state.applicant_information.department_name = responseData.department_name;
    state.applicant_information.hr_name = responseData.hr_name;
    state.applicant_information.hr_phone = responseData.hr_phone;
    state.applicant_information.salary_payday = responseData.salary_payday;

    state.applicant_information.have_business_license = responseData.have_business_license;
  
    state.applicant_information.have_pay_tax_for_last_three_years = responseData?.have_pay_tax_for_last_three_years;
    
    // if(state.applicant_information.have_pay_tax_for_last_three_years == 1) {
    //   state.applicant_information.company_register_number = responseData?.company_register_number;
    //   state.applicant_information.tax_payment_last_date = responseData?.tax_payment_last_date;
    // } else {
    //   alert("Yes")
    // }

    state.applicant_information.company_register_number = responseData?.company_register_number;
    state.applicant_information.tax_payment_last_date = responseData?.tax_payment_last_date;

    // if (state.applicant_information.investment) {
      state.applicant_information.investment_amount = responseData?.investment_amount
    // console.log('SS', state.applicant_information.investment_amount);

      state.applicant_information.currency_code = responseData?.currency_code
    // }

    state.applicant_information.years_of_service = responseData.years_of_service;
    state.applicant_information.months_of_service = responseData.months_of_service;
    state.applicant_information.total_income = responseData?.total_income;
    

    // if (responseData.address) {
    state.company_address.country_state_id = responseData.company_address?.country_state_id;
    state.company_address.city_id = responseData.company_address?.city_id;
    state.company_address.township_id = responseData.company_address?.township_id;

    state.company_address.place_type = responseData.company_address?.place_type;

    state.company_address.ward_or_village_name = responseData.company_address?.ward_or_village_name;
    state.company_address.street = responseData.company_address?.street;
    
    state.company_address.building_number = responseData.company_address?.building_number;
    state.company_address.floor = responseData.company_address?.floor;
  },

  addPersonalStateData: (state, payload) => {
    state.applicant_information.nrc_state_id = payload?.id;
    state.applicant_information.nrc_state_burmese_number =
       payload.burmese_code;
    state.applicant_information.nrc_state_number = payload.code;
 },

 addPersonalDistrictData: (state, payload) => {
    state.applicant_information.nrc_district_id = payload?.id;
    state.applicant_information.nrc_district_burmese_name =
       payload.burmese_code;
    state.applicant_information.nrc_district_name = payload.code;
 },

 addPersonalNRCtypeData: (state, payload) => {
    state.applicant_information.nrc_type_id = payload?.id;
    state.applicant_information.nrc_type_burmese_name =
       payload.burmese_name;
    state.applicant_information.nrc_type_name = payload.name;
 },

  addAddressRegionData: (state, payload) => {
    state.applicant_information.country_state_id = payload.id;
  },
  addAddressCityData: (state, payload) => {
    state.applicant_information.city_id = payload.id;
  },
  addAddressTownshipData: (state, payload) => {
    state.applicant_information.township_id = payload.id;
  },

  addCompanyRegionData: (state, payload) => {
    state.company_address.country_state_id = payload.id;
  },
  addCompanyCityData: (state, payload) => {
    state.company_address.city_id = payload.id;
  },
  addCompanyTownshipData: (state, payload) => {
    state.company_address.township_id = payload.id;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
