import "./styles/quasar.sass";
import "@quasar/extras/material-icons/material-icons.css";

import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';;

export default {
   config: {
      notify: {},
      loading: {},
   },
   plugins: {
      Notify,
      Loading,
   },
};
